.owl-animation-enter {
    opacity: 0;
    transition: opacity 300ms ease-in;
}

.owl-animation-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.owl-animation-enter-done {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.owl-animation-exit {
    opacity: 1;
    transition: opacity 200ms ease-out;
}

.owl-animation-exit-active {
    opacity: 0;
    transition: opacity 200ms ease-out;
}

.owl-animation-exit-done {
    opacity: 0;
    transition: opacity 200ms ease-out;
    z-index: -1 !important;
}

