.BurgerMenuIcon {
    width: 26px;
    height: 26px;
    position: relative;
    margin: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

.BurgerMenuIcon span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #FFF;
    border-radius: 3px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

.BurgerMenuIcon span:nth-child(1) {
    top: 0;
}

.BurgerMenuIcon span:nth-child(2),.BurgerMenuIcon span:nth-child(3) {
    top: 9px;
}

.BurgerMenuIcon span:nth-child(4) {
    top: 18px;
}

.BurgerMenuIcon.open span:nth-child(1) {
    top: 9px;
    width: 0%;
    left: 50%;
}

.BurgerMenuIcon.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.BurgerMenuIcon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.BurgerMenuIcon.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
}