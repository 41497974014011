.rtf {
    z-index: 20;
}

.rtf.open .rtf--mb > * {
    transform-origin: center center;
    transform: rotate(180deg) scale(0);
    transition: ease-in-out transform .3s;
}

.rtf--mb {
    transition: background-color .3s ease-out;
    background-color: #35BB9A;
}

.rtf--mb:hover {
    background-color: #2a9379;
}

.rtf--mb:after {
    display: block;
    content: '';
    width: 21px;
    height: 21px;
    position: absolute;
    transform: rotate(-180deg) scale(0);
    transition: ease-in-out transform .3s;
    background: url('../../../assets/fabChat/close.svg') no-repeat center;
    background-size: contain;
}

.rtf.open .rtf--mb:after {
    transform: rotate(0) scale(1);
}

.rtf.open .rtf--mb {
    background-color: #808085;
}