@keyframes shadow {
    from {
        transform: scale(0.9);
        opacity: 1;
        border: 1px solid #2E3039;
    }
    to {
        transform: scale(1.5);
        opacity: 0;
        border: 3px solid #2E3039;
    }
}

.stage-round-next-step {
    position: absolute;
    background: rgba(52, 54, 65, 0.68);
    width: 40px;
    height: 40px;
    border-radius: 40px;
    border: 1px solid #2E3039;
    animation: shadow 1.2s infinite;
}

.stage-round-next-step-modifer {
    background: #2E303A;
}