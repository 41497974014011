.fabMobile {
    position: fixed;
    bottom: 0;
    right: 10px;
    z-index: 20;
    background-color: #35BB9A;
    background-repeat: no-repeat;
    justify-content: center;
    border-radius: 20px 20px 0 0;
    box-sizing: border-box;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), 0 1px 0 1px rgba(0, 0, 0, .05), 0 4px 6px 0 rgba(0, 0, 0, .1);
    cursor: pointer;
    height: 35px;
    width: 50px;
    outline: 0;
    transition: all .3s ease-out;
    user-select: none;
    -webkit-font-smoothing: antialiased;
    -webkit-touch-callout: none;
    will-change: background-color;
    display: flex;
}

.fabMobile .actions {
    border-radius: 20px 20px 0 0;
    transition: all .3s ease-out;
    padding-bottom: 40px;
    padding-top: 5px;
    will-change: transform;
    position: fixed;
    bottom: 0;
    width: 50px;
    background-color: #35BB9A;
    transform: translateY(100%);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.fabMobile.opened .actions {
    transform: translateY(0);
    background-color: #343641;
}

.fabMobile .BubbleIcon {
    position: absolute;
    bottom: 1px;
    left: 10px;
    transform: rotate(0) scale(1);
    transition: all .3s ease-out;
}

.fabMobile.opened .BubbleIcon {
    transform: rotate(180deg) scale(0);
}

.fabMobile .CloseIcon {
    position: absolute;
    bottom: 0;
    transform: rotate(-180deg) scale(0);
    transition: all .3s ease-out;
}

.fabMobile.opened .CloseIcon {
    transform: rotate(0) scale(1);
}

.fabMobile .actions > div {
    margin: 7px;
    transform: scale(0);
    transition: all .1s ease-out;
    transition-delay: 0s;
}

.fabMobile.opened .actions > div {
    transform: scale(1);
    transition: all .3s ease-out;
    transition-delay: .2s;
}