#promo_v2 {
  @media screen and (max-width: 600px) {
    .promo__header {
      height: 50px;
    }

    .promo__video.promo__video--activated {
      margin-bottom: 40px;
    }

    .promo__section1 {
      margin-top: -25px;
    }

    .promo__section1 h1 {
      font-size: 24px;
      margin-top: 0;
    }

    .promo__video {
    }

    .promo__section1 h4 {
      font-size: 16px;
      margin-top: 13px;
    }

    .promo h3 {
      font-size: 20px;
      line-height: 25px;
      width: auto;
    }

    .promo__section1 h3 {
      width: 270px;
      margin: 0 auto;
      margin-top: 10px;
    }

    .benefit1_col--0 {
      width: 270px;
      margin: 0 auto;
      margin-top: 22px;
    }

    .benefit1_cols--2 {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
    }

    .benefit1_col--1 {
      margin-left: 0;
      margin-right: 0;
    }

    .benefit1_col--2 {
      margin-left: 0;
      margin-right: 0;
    }

    .benefit1_col__header {
      font-size: 18px;
      margin-top: 10px;
    }

    .benefit1_col__text {
      padding-top: 9px;
      line-height: 18px;
    }

    .benefit1_col--1 {
      margin-bottom: 43px;
    }

    .feedback_item__person__avatar {
      width: 40px;
      min-width: 40px;
      height: 40px;
      margin-top: 5px;
      min-height: 40px;
    }

    .feedback_item__person__title {
      padding-top: 0;
    }

    .feedback_item__person__name {
      font-size: 16px;
    }

    .feedback_item__person__position {
      font-size: 12px;
      line-height: 15px;
    }

    .feedback_items__message {
      padding: 0;
      padding-top: 10px;
    }

    .feedback_items {
      width: 320px;
    }

    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }

    .feedback_items .slide-item-root {
      padding: 0 10px;
      padding-bottom: 40px;
    }

    .promo__cards-1 {
      flex-direction: column;
      align-items: center;
      background-position: 20% top;
      padding: 40px 0;
    }

    .promo__card + .promo__card {
      margin-top: 20px;
    }

    .promo__section5 {
      padding: 20px;
    }

    .promo__section7 {
      padding: 20px;
    }

    .promo_utp1 {
      width: 100%;
    }

    .promo_utp1 p {
      width: auto;
      padding: 0 20px;
    }

    .promo_utp1 h2 {
      padding: 0 20px;
    }

    .promo__section8 {
      width: 100%;
      padding: 0 20px;
    }

    .promo_features {
      max-width: 600px;
      width: auto;
    }

    .promo_feature__card {
      margin: 0 10px;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .promo_feature__card:first-child {
      border-radius: 10px 10px 0 0;
    }

    .promo_feature__card + .promo_feature__card {
      border-radius: 0;
      border-top: 1px solid #5B5D66;
    }

    .promo_feature__card:last-child {
      border-radius: 0 0 10px 10px;
    }

    .promo_feature__card__number {
      left: 13px;
      top: 50%;
      transform: translateY(-50%);
    }

    .promo_feature__card__main-text {
      font-size: 18px;
      line-height: 18px;
      top: 20px;
      right: -30px;
    }

    .promo_feature__card__main-text, .promo_feature__card__mind {
      position: static;
      height: auto;
      width: auto;
    }

    .promo_feature__card__main-text {
      padding-right: 15px;
      padding-top: 20px;
      padding-left: 90px;
    }

    .promo_feature__card__mind {
      padding-right: 15px;
      padding-top: 15px;
      padding-bottom: 20px;
      padding-left: 90px;
    }

    .promo_features .promo_feature__card:nth-child(8) .promo_feature__card__main-text,
    .promo_features .promo_feature__card:nth-child(9) .promo_feature__card__main-text,
    .promo_features .promo_feature__card:nth-child(8) .promo_feature__card__mind,
    .promo_features .promo_feature__card:nth-child(9) .promo_feature__card__mind {
      width: auto;
    }

    .promo__inlineForm__megaText {
      font-size: 20px;
      line-height: 30px;
    }

    .promo__inlineForm__content__form {
      width: 100%;
      padding: 0 20px;
    }

    .promo__inlineForm__bg {
      margin-right: 10px;
      margin-left: 10px;
      width: calc(100% - 20px);
    }

    .promo__inlineForm__content__form button {
      font-size: 12px;
      padding: 0 10px;
    }

    .promo_owl {
      margin-right: 10px;
      margin-left: 10px;
      width: calc(100% - 20px);
    }

    .promo__section5 {
      padding-right: 0;
      padding-left: 0;
      margin-top: 20px;
    }

    .promo__section8 {
      width: auto;
      .content {
        .digits {
          .item {
            width: calc(100% - 40px);
          }
        }
      }
    }
  }

  @media screen and (max-width: 400px) {
    .promo__section1 h1 {
      font-size: 20px;
    }
  }
}
