:root {
  --swiper-navigation-color: #42A6FF;
  --swiper-pagination-color: #42A6FF;
  --swiper-theme-color: #42A6FF;
}

#promo_v2 {
  .promo {
    background: #343641;
    width: 100%;
    height: 100%;
    color: #ffffff;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .promo__container {
    width: 100%;
    height: 100%;
  }

  .promo__video {
    max-width: 768px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    transition: all 0.5s ease-in-out;
    margin: 0 auto;
    margin-top: 50px;
  }

  .promo__video:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 90vw;
    height: 0;
    box-shadow: 0 0 75px 110px #343641;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    margin-bottom: 0;
  }

  .promo__video.promo__video--activated:after {
    opacity: 0;
  }

  .promo__video.promo__video--activated {
    margin-bottom: 90px;
  }

  .promo__section1 {
    z-index: 1;
    position: relative;
    margin-top: -75px;
  }

  .promo__section1 h1 {
    font-family: 'Merriweather', serif;
    font-size: 50px;
    color: #FFFFFF;
    font-weight: bold;
    letter-spacing: 0;
    text-align: center;
    margin: 0;
    padding: 0;
  }

  .promo__section1 h4 {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 24px;
    font-style: italic;
    color: rgba(255, 255, 255, 0.50);
    letter-spacing: 0;
    text-align: center;
    font-weight: 400;
    margin: 0;
    padding: 0;
    position: relative;
    top: -18px;
  }

  .promo h3 {
    font-family: 'Merriweather', serif;
    font-size: 32px;
    color: #FFFFFF;
    text-align: center;
    text-transform: uppercase;
    line-height: 40px;
    font-weight: 300;
    letter-spacing: 0.1em;
  }

  .promo__section1 h3 {
    font-family: 'Merriweather', serif;
    font-size: 32px;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;
    text-transform: uppercase;
    margin-top: 21px;
    line-height: 40px;
    font-weight: 300;
  }

  .benefit1_cols {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .benefit1_col {
    width: 250px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
  }

  .benefit1_col__image {
    width: 100px;
    height: 40px;
  }

  .benefit1_col__image--1 {
    background: url("./images/person.svg") no-repeat center;
  }

  .benefit1_col__image--2 {
    background: url("./images/diamond.svg") no-repeat center;
  }

  .benefit1_col + .benefit1_col:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 270px;
    background: #5B5D66;
    top: 0;
    left: -16px;
  }

  .benefit1_col__header {
    font-weight: bold;
    font-size: 24px;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;
    margin-top: 13px;
  }

  .benefit1_col__text {
    font-size: 16px;
    color: #9B9B9B;
    letter-spacing: 0;
    text-align: center;
    padding-top: 17px;
    line-height: 19px;
  }

  .benefit1_col--1 {
    width: 222px;
    margin-right: 32px;
    margin-left: 27px;
  }

  .benefit1_col--2 {
    width: 253px;
    margin-right: 32px;
  }

  .promo__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    position: relative;
    top: -5px;
  }

  .promo__buttons__header {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.50);
    letter-spacing: 0;
    text-align: center;
    padding-bottom: 7px;
  }

  .promo__button {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 0 1px rgba(0, 0, 0, 0.05), 0 4px 6px 0 rgba(0, 0, 0, 0.10);
    border: 1px solid rgba(255, 255, 255, 0.20);
    border-radius: 82px;
    font-weight: bold;
    height: 50px;
    width: 220px;
    font-size: 12px;
    color: #FFFFFF;
    letter-spacing: 1px;
    text-align: center;
    outline: none;
    cursor: pointer;
    transition: all .15s ease-out;
    transform: perspective(1px) scale(1);
    will-change: transform;
  }

  .promo__button--dark {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 1px 0px 1px, rgba(0, 0, 0, 0.1) 0px 4px 6px 0px;
  }

  .promo__button:hover {
    transform: perspective(1px) scale(1.05);
  }

  .promo__button:active {
    transform: perspective(1px) scale(.95);
  }

  .promo__button--green {
    background: #34BB9A;
    border: 1px solid #34BB9A;
  }

  .promo__button--green:hover {
    background: rgb(42, 147, 121);
    border: 1px solid rgb(42, 147, 121);
  }

  .promo__button--dark:hover {
    background-color: rgba(255, 255, 255, 0.05);
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
  }

  .promo__buttons__or {
    padding-top: 7px;
    padding-bottom: 7px;
    position: relative;
  }

  .promo__buttons__or:before {
    content: '';
    position: absolute;
    left: -90px;
    top: 20px;
    width: 70px;
    height: 1px;
    background: #5B5D66;
  }

  .promo__buttons__or:after {
    content: '';
    position: absolute;
    right: -90px;
    top: 20px;
    width: 70px;
    height: 1px;
    background: #5B5D66;
  }

  .benefit1_col--0 {
    display: none;
  }

  .promo__section3 h3 {
    margin-top: 61px;
    margin-bottom: 37px;
  }

  .feedback_items {
    & * {
      box-sizing: border-box;
    }
    .slide-item-root {
      padding: 0 70px;
      padding-bottom: 40px;
    }
    overflow: hidden;
    width: 800px;
    margin: 0 auto;
    margin-top: 20px;
  }

  .feedback_item {
    overflow: auto;
    background: #FCFBFC;
    border-radius: 5px;
    color: #1c1c1c;
    padding: 25px;
    display: flex;
    flex-direction: column;
    user-select: none;
  }

  .feedback_item__person__avatar {
    width: 80px;
    height: 80px;
    min-width: 80px;
    min-height: 80px;
    border-radius: 80px;
    background-color: #1c1c1c;
    background-position: center;
    background-size: cover;
  }

  .feedback_item---2 .feedback_item__person__avatar {
    background-image: url('./images/avatars/1.jpeg');
  }

  .feedback_item---1 .feedback_item__person__avatar {
    background-image: url('./images/avatars/2.jpeg');
  }

  .feedback_item---3 .feedback_item__person__avatar {
    background-image: url('./images/avatars/3.jpeg');
  }

  .feedback_item---4 .feedback_item__person__avatar {
    background-image: url('./images/avatars/4.png');
  }

  .feedback_item---5 .feedback_item__person__avatar {
    background-image: url('./images/avatars/5.jpeg');
  }

  .feedback_item---6 .feedback_item__person__avatar {
    background-image: url('./images/avatars/6.jpeg');
  }

  .feedback_item---7 .feedback_item__person__avatar {
    background-image: url('./images/avatars/7.png');
  }

  .feedback_item__person {
    display: flex;
  }

  .feedback_item__person__name {
    font-size: 20px;
    color: #000000;
    line-height: 31px;
    font-weight: bold;
  }

  .feedback_item__person__position {
    font-size: 16px;
    color: #000000;
    color: rgba(0, 0, 0, 0.50);
    font-weight: 400;
    line-height: 16px;
    margin-top: 10px;
  }

  .feedback_items__message {
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    color: #1C1C1C;
    display: flex;
    padding-left: 100px;
    align-items: center;
    padding-top: 6px;
    line-height: 20px;
    flex: 1;
    padding-right: 40px;
  }

  .feedback_item__person__title {
    padding-left: 20px;
    padding-top: 10px;
  }

  .promo__cards-1 {
    background: url('./images/dark.png') no-repeat center;
    background-size: cover;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    padding: 100px 200px;
    margin-top: 42px;
    border-radius: 15px;
  }

  .promo__card {
    background: #fff;
    width: 180px;
    height: 220px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
  }

  .promo__card__text {
    font-weight: 400;
    font-size: 14px;
    color: #1C1C1C;
    text-align: center;
    padding-top: 30px;
    line-height: 18px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .promo__card__image {
    height: 60px;
    width: 100px;
    background-size: contain;
    background-position: center;
  }

  .promo__card--1 .promo__card__image {
    background-image: url('./images/icon-1.svg');
  }

  .promo__card--2 .promo__card__image {
    background-image: url('./images/icon-2.svg');
  }

  .promo__card--3 .promo__card__image {
    background-image: url('./images/icon-3.svg');
  }

  .promo__section5 {
    margin-top: 45px;
  }

  .promo_owl {
    background: url('./images/owl-big.png');
    width: 765px;
    height: 317px;
    background-size: contain;
    background-position: center;
    font-family: 'Neucha', sans-serif;
    color: #1C1C1C;
    margin: 0 auto;
  }

  .promo_owl__header {
    font-size: 35px;
    width: 400px;
    position: relative;
    left: 284px;
    text-align: center;
    top: 28px;
    line-height: 38px;
  }

  .promo_owl__message {
    font-size: 16px;
    position: relative;
    top: 55px;
    width: 510px;
    left: 230px;
  }

  .promo_features {
    width: 940px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    margin-top: 26px;
    justify-content: center;
  }

  .promo_feature__card {
    width: 450px;
    height: 158px;
    background: #fff;
    color: #1c1c1c;
    border-radius: 10px;
    position: relative;
    margin: 0 10px;
    margin-top: 20px;
  }

  .promo_feature__card__number {
    font-family: 'Merriweather', serif;
    font-weight: bold;
    font-size: 48px;
    color: #9B9B9B;
    text-align: center;
    position: absolute;
    left: 35px;
    top: 43px;
  }

  .promo_feature__card__main-text {
    font-size: 20px;
    font-weight: bold;
    color: #1C1C1C;
    text-align: right;
    line-height: 18px;
    padding-right: 45px;
    position: absolute;
    top: 30px;
    right: 0;
    width: 350px;
    display: inline-flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 35px;
  }

  .promo_feature__card__mind {
    font-family: 'Merriweather', serif;
    font-size: 12px;
    line-height: 14px;
    color: #232323;
    text-align: right;
    padding-right: 45px;
    position: absolute;
    top: 97px;
    right: 7px;
    width: 350px;
  }

  .promo__section7 {
    margin-top: 45px;
  }

  .promo_utp1 {
    width: 760px;
    border: 1px solid #979797;
    border-radius: 15px;
    margin: 0 auto;
    padding-bottom: 30px;
  }

  .promo_utp1 h2 {
    font-family: 'Merriweather', serif;
    font-size: 28px;
    color: #FFFFFF;
    letter-spacing: 1.56px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 300;
    margin-top: 31px;
    line-height: 34px;
  }

  .promo_utp1 button {
    margin: 0 auto;
    display: block;
    margin-top: 42px;
    width: 230px;
  }

  .promo_utp1 p {
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
    text-align: center;
    font-weight: 400;
    width: 600px;
    margin: 0 auto;
    margin-top: 40px;
  }

  .promo__section8 {
    font-family: 'Merriweather', serif;
    font-size: 16px;
    color: #FFFFFF;
    text-align: justify;
    font-weight: 400;
  }

  .promo__section8 h3 {
    letter-spacing: 0.1em;
    margin-top: 45px;
    margin-bottom: 24px;
  }

  .promo__section8 p {
    margin-bottom: 20px;
  }

  .promo__header__fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    width: 100%;
    background: #343641;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 0 1px rgba(0, 0, 0, 0.05), 0 4px 6px 0 rgba(0, 0, 0, 0.10);
  }

  .promo__header {
    max-width: 1200px;
    height: 50px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .promo__header__logo {
    width: 100px;
  }

  .promo__header__login {
    color: #fff;
    text-decoration: none;
    height: 50px;
    line-height: 50px;
    width: 60px;
    text-align: right;
  }

  .promo__header__login.promo__header__login--120 {
    width: 140px;
  }

  footer {
    background: #171717;
    width: 100%;
    margin-top: 40px;
  }

  .footer__content {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .footer__logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .footer__logo__sub {
    color: #ffff;
    font-size: 12px;
    text-align: center;
    padding-top: 10px;
    width: 190px;
  }

  .footer__logo__image {
    background: url('./images/small-white-logo.svg') no-repeat center;
    width: 91px;
    height: 76px;
  }

  .footer__payment__logo {
    margin-top: 40px;
    padding: 5px;
    border-radius: 5px;
    background: #F5F5F5 url('../../assets/paykeeper.svg') no-repeat center;
    width: 296px;
    height: 39px;
  }

  .footer__link {
    color: #fff;
    display: block;
  }

  .footer__copy {
    font-size: 14px;
    padding-top: 40px;
    display: block;
  }

  .footer_c_col a {
    color: #fff;
    display: block;
  }

  .footer__header {
    color: #fff;
    font-weight: bold;
  }

  .feedback_item__person__position a {
    color: #000;
  }

  .promo__section100 {
    max-width: 400px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }

  .promo__section100 button {
    width: 300px;
  }

  .other-section {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .promo__section3.isFinal {
    order: 10;
  }

  .promo__section7.isFinal {
    order: 20;
  }

  .promo__section7.isFinal button {
    width: 300px;
  }

  .promo_feature__card__flags {
    background: url('./images/flags.png') no-repeat left top;
    width: 106px;
    height: 10px;
    background-size: contain;
    display: inline-block;
  }

  .promo__inlineForm {
    margin-top: 60px;
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .promo__inlineForm__megaText {
    font-family: 'Merriweather', serif;
    font-size: 40px;
    line-height: 50px;
    font-weight: bold;
    color: #232323;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }

  .promo__inlineForm__bg {
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 940px;
    padding-bottom: 10px;
  }

  .promo__inlineForm__content {
    width: 600px;
    margin: 0 auto;
  }

  .promo__inlineForm__content__form {
    width: 400px;
    margin: 0 auto;
  }

  .promo__section8 {
    width: 800px;
    margin: 0 auto;
    .content {
      .digits {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

        .item {
          width: calc(33% - 40px);
          margin: 20px;

          & div:nth-child(1) {
            border-bottom: 2px solid white;
            font-size: 30px;
            padding-bottom: 5px;
          }

          & div:nth-child(2) {
            text-align: left;
          }
        }
      }
    }
  }
}
