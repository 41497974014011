.no-scroll {
    overflow: hidden !important;
    position: fixed;
    width: 100%;
    height: 100%;
}

#app.no-scroll {
    pointer-events: none;
}

.hidden-ui {
    display: none;
    opacity: 0;
}

.pressable-button {
    transform: perspective(1px) scale(1);
    backface-visibility: hidden;
    will-change: transform;
    transition: all ease 0.3s;
}

@media not all and (pointer: coarse) {
    .pressable-button:hover {
        transform: perspective(1px) scale(1.05);
    }
}

.pressable-button:active {
    transform: perspective(1px) scale(0.95);
}

.SpotLightOverlay__spotlight__padding_10 {
    padding: 10px;
}

.r-phone-input {
    margin-top: 10px;
}

.r-phone-input.r-phone-input-error input {
    border-color: #A01836;
}

.r-phone-input input {
    border: 1px solid rgba(0, 0, 0, .25);
    border-radius: 5px;
    color: #1C1C1C;
    display: inline-block;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 15px;
    height: 50px;
    outline: none;
    padding: 0 10px;
    padding-left: 60px;
    transition: border-color .3s ease-out;
    width: 100%;
}

.r-phone-input input::placeholder {
    color: #808085;
    opacity: 1;
}

.r-phone-input input:focus {
    outline: none;
    border-color: #000000;
}

.r-phone-input input:disabled {
    border: 1px dotted rgba(0, 0, 0, 0.25);
    opacity: 0.5;
    cursor: not-allowed;
}

.react-phone-number-input__row {
    position: relative;
}

.react-phone-number-input__row .react-phone-number-input__country {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 50px;
    height: 48px;
    border-radius: 5px 0 0 5px;
    background: #EBEBED;
}

.react-phone-number-input__icon {
    position: relative;
    left: 10px;
    border: none;
}

.react-phone-number-input__icon img {
    position: absolute;
}

.react-phone-number-input__country-select-arrow {
    left: 10px;
    position: relative;
    color: #1c1c1c;
}

.link_to_library {
    color: #0000EE;
}