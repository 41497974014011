#promo_v2 {
  @media screen and (max-width: 768px) {
    .promo__section1 h1 {
      font-size: 34px;
      margin-top: 10px;
    }

    .promo__section1 h4 {
      font-size: 16px;
      margin-top: 6px;
    }

    .footer__content {
      width: 100%;
      flex-wrap: wrap;
      padding: 40px 40px;
      justify-content: space-between;
    }

    .footer_c_col {
      width: 100%;
      text-align: center;
      padding-bottom: 20px;
    }

    .footer_r_col {
      width: 100%;
      text-align: center;
    }

    .footer_l_col {
      width: 100%;
    }

    .footer__copy {
      padding-top: 20px;
      padding-bottom: 20px;
      text-align: center;
    }

    .footer__payment__logo {
      margin: 0 auto;
      margin-top: 20px;
    }

    .footer__content {
      padding: 40px 0;
    }

    .feedback_items {
      width: 600px;
    }

    .feedback_items .slide-item-root {
      padding: 0 50px;
      padding-bottom: 40px;
    }

    .feedback_item__person__name {
      line-height: 18px;
    }

    .feedback_items__message {
      font-size: 14px;
      line-height: 18px;
      padding: 10px;
    }

    .promo_owl {
      background: none;
      background: #fff;
      padding: 25px;
      border-radius: 15px;
      width: calc(100% - 80px);
      height: auto;
      margin: 0 40px;
    }

    .promo_owl__header {
      position: static;
      width: auto;
      padding-bottom: 10px;
      font-size: 22px;
      line-height: 23px;
    }

    .promo_owl__message {
      position: static;
      width: auto;
      font-size: 14px;
      line-height: 18px;
    }

    .promo__section100 {
      margin-top: 35px;
    }

    .promo__inlineForm__megaText {
      font-size: 30px;
      line-height: 40px;
      padding: 0 20px;
    }

    .promo__inlineForm {
      margin-top: 25px;
      margin-bottom: 0;
    }

    .promo__section8 {
      width: 600px;
      .content {
        .digits {
          .item {
            width: calc(50% - 40px);
          }
        }
      }
    }
  }
}
