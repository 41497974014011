.b24-widget-button-wrapper {
    font-family: 'Helvetica Neue', Helvetica,Arial, sans-serif;
}

.b24-widget-button-position-bottom-right .b24-widget-button-social-item:hover .b24-widget-button-social-tooltip {
    background: #343642;
    width: 100px;
    color: #fff;
    border-radius: 6px;
}

.b24-widget-button-wrapper.b24-widget-button-position-bottom-right {
    visibility: hidden;
    display: none;
}
