#promo_v2 {
  @media screen and (max-width: 1200px) {
    .promo__section1 h1 {
      font-size: 40px;
      margin-top: 10px;
    }

    .promo__header {
      max-width: 768px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .feedback_items {
      margin-top: 8px;
    }

    .promo__section1 h4 {
      font-size: 20px;
      margin-top: 6px;
    }

    .benefit1_col--0 {
      display: block;
    }

    .promo h3 {
      font-size: 28px;
      line-height: 36px;
    }

    .promo__section1 h3 {
      margin-top: 9px;
      margin-bottom: 23px;
    }

    .benefit1_col--1 {
      order: 2;
      margin-left: 7px;
    }

    .benefit1_col--2 {
      order: 3;
      margin-left: 30px;
      margin-right: 0;
    }

    .benefit1_col--3 {
      display: none;
      visibility: hidden;
    }

    .benefit1_cols {
      margin-top: 0;
    }

    .benefit1_cols--2 {
      margin-top: 16px;
    }

    .benefit1_col + .benefit1_col:after {
      display: none;
    }

    .promo__section3 h3 {
      width: 470px;
      margin: 0 auto;
      margin-top: 54px;
      display: block;
      letter-spacing: 1.56px;
    }

    .feedback_items {
      margin-top: 8px;
    }

    .promo__cards-1 {
      width: calc(100% - 40px);
      margin-left: 20px;
      margin-right: 20px;
    }

    .promo__cards-1 {
      padding: 100px 10px;
    }

    .promo_features {
      margin-top: 51px;
      width: 600px;
    }

    .promo_feature__card {
      height: 110px;
      width: 100%;
      margin-right: 0;
      margin-left: 0;
      margin-top: 5px;
    }

    .promo_feature__card__number {
      top: 19px;
    }

    .promo_feature__card__main-text {
      top: 24px;
      right: -10px;
      align-items: flex-start;
      font-size: 18px;
      line-height: 18px;
      width: 460px;
    }

    .promo_feature__card__mind {
      bottom: 25px;
      right: -8px;
      top: initial;
    }

    .promo_features .promo_feature__card:nth-child(4) .promo_feature__card__main-text {
      top: 15px;
    }

    .promo_features .promo_feature__card:nth-child(4) .promo_feature__card__mind {
      top: initial;
      bottom: 15px;
    }

    .promo_features .promo_feature__card:nth-child(8) .promo_feature__card__main-text {
      top: 15px;
      width: 280px;
    }

    .promo_features .promo_feature__card:nth-child(8) .promo_feature__card__mind {
      width: 420px;
    }

    .promo_features .promo_feature__card:nth-child(9) .promo_feature__card__main-text {
      width: 420px;
    }

    .promo_utp1 {
      max-width: 600px;
    }

    .promo_utp1 h2 {
      letter-spacing: 0.05em;
      font-size: 24px;
      margin-top: 42px;
    }

    .promo_utp1 button {
      margin-top: 30px;
    }

    .promo_utp1 p {
      font-size: 18px;
      line-height: 21px;
      margin-top: 24px;
      max-width: 500px;
    }

    .promo__section8 h3 {
      letter-spacing: 0.05em;
    }

    .promo__section8 p {
      font-size: 16px;
      line-height: 20px;
    }

    .footer__content {
      width: 768px;
    }

    .promo__inlineForm__megaText {
      font-size: 35px;
      line-height: 45px;
    }

    .promo__inlineForm__bg {
      width: calc(100% - 80px);
      margin-left: 40px;
      margin-right: 40px;
    }
  }
}
